@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=4001043f-7f8c-414a-af6e-4a5c2c21c0f8&fontids=692088,6005273");
@font-face{
    font-family:"Century Gothic";
    src: local('Century Gothic Bold'), url("./6005273/4e621b58-4586-429c-a0b2-9470ab4ae8c0.woff2") format("woff2");
    src: local('Century Gothic Bold'), url("./6005273/97e17f80-aaf1-4b74-a33b-e5dd730a26fd.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face{
    font-family: "Century Gothic";
    src: local('Century Gothic Regular'), url("./692088/700cfd4c-3384-4654-abe1-aa1a6e8058e4.woff2") format("woff2");
    src: local('Century Gothic Regular'), url("./692088/9908cdad-7524-4206-819e-4f345a666324.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}