.data-grid {
    display: grid;
    grid-template-columns: 120px 1fr;
    column-gap: 15px;
    row-gap: 10px;
    > span {
        font-weight: bold;
        > div {
            font-weight: normal;
        }
    }
}