header {
    height: var(--headerHeight);
    background-color: white;
    border-bottom: 1px solid var(--colorLine);
    padding: 0 var(--contentPadding);
    top: 0px;
    width: 100%;
    display: grid;
    grid-template-columns: 150px 1fr;
    align-items: center;
    z-index: 1;
    > div, > a {
        img {
            width: 113px;
        }
        span {
            position: absolute;
            bottom: 3px;
            right: 0;
            font-size: var(--fontSizeS);
            color: var(--colorTextLight);
        }
    }
    > h5 {
        font-size: var(--fontSizeXL);
        font-weight: bold;
        display: flex;
        align-items: center;
        gap: 13px;
        justify-self: end;
        >.lx-avatar {
            background: linear-gradient(358.79deg, #5754FF -20.83%, #007AFF 97.93%);
        }
        > div {
            line-height: 16px;
            font-size: var(--fontSizeL);
        }
    }
}