#lax {
    .btn-ref  {
        &.selected, &:not([disabled]):hover {
            background-color: var(--colorError);
            .anticon{
                color: white;
            }
        }
        .anticon{
            color: var(--colorError);
        }
    }
}