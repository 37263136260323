#lax {
    .lx-popconfirm {
        .lx-popover-inner {
            padding: 20px 24px;
            .lx-popconfirm-title {
                font-size: var(--fontSizeXL);
                padding-bottom: 12px;
            }
            .lx-popconfirm-message-icon {
                .anticon {
                     font-size: 22px;
                     color: var(--colorWarning);
                }
             }
            .lx-popconfirm-description {
               margin: 0 0 7px 0;
            }
        }
        &.lx-popconfirm.info {
            .anticon {
                font-size: 22px;
                color: var(--colorPrimary);
           }
        }
    }
}