#lax {
    .textarea-injury-survey {
        width: 100%;
        > div {
            background-color: var(--backgroundDisabled);
            color: var(--colorDisabled);
            border-radius: var(--borderRadius);
            font-weight: bold;
            border: 1px var(--colorBorder) solid;
            border-bottom: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            display: block;
            padding: 7px 11px;
            white-space: pre-wrap;
            & + .lx-input-affix-wrapper {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }
}