#lax {
    .lx-drawer {
        .lx-drawer-content  {
            background-color: #F6F6F6;
            .lx-drawer-header {
                background-color: white;
                .lx-result {
                    padding: 12px 0;
                    .lx-result-icon {
                        margin: 25px 0;
                        .anticon {
                            font-size: 36px;
                        }
                    }
                    .lx-result-title {
                        font-size: 20px;
                        padding-bottom: 0px;
                    }
                }
            }
        }
    }
}