html, body {
    width: 100%;
    scroll-behavior: smooth; 
}
body {
    background-color: var(--appBackground);
    font-family: var(--fontFamily);
    font-size: var(--fontSize);
    color: var(--colorText);
    > iframe {
        pointer-events: none;
    }
}
* {
    font-family: var(--fontFamily);
    box-sizing: border-box;
    margin: 0;
    // removed because hides pseudo elements on ol/ul
    // padding: 0;
    position: relative;
    outline: none;
    border: none;
}
[type="search"]::-webkit-search-decoration,
[type="search"]::-webkit-search-cancel-button {
  appearance: none;
}
::placeholder {
    font-weight: normal;
    color: var(--colorGray);
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-track {
    background-color: var(--appBackground);
    opacity: .6;
}
::-webkit-scrollbar-thumb {
    background-color: var(--scrollbarThumb);
    border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
    background: #99a0b0; 
}
::-webkit-scrollbar-corner {
    background: var(--appBackground);
}
