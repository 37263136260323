#lax {
    fieldset {
        padding: 17px 17px;
        margin-bottom: 19px;
        background-color: var(--fieldsetBackground);
        box-shadow: var(--fieldsetBoxShadow);
        border-radius: var(--borderRadius);
        .anticon-close-circle {
            float: right;
            cursor: pointer;
            transition:  opacity 0.3s ease;
            color: var(--colorGray); 
            opacity: .3;
            &:hover {
                opacity: 1;
            }
        }
        .form-category-header {
            margin: 0 0 6px 0;
            border-bottom: 1px solid var(--colorLine);
            line-height: 26px;
            display: grid;
            grid-template-columns: 1fr 12px;
            column-gap: 10px;
            align-items: center;
            >.title {
                font-size: var(--fontSizeXL);
                font-weight: bold;
                color: var(--colorPrimary);
                > .scroll-to-fieldset {
                    position: relative;
                    top: -102px;
                }
            }
            >.actions {
                padding-bottom: 2px;
                button {
                    margin-left: 7px;
                }
            }
        }
    }
}