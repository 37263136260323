#lax {
   .lx-btn {
        border-width: 1px;
        font-weight: bold;
        &.lx-btn-text.lx-btn-dangerous:not([disabled]):not(:hover) {
            background-color: var(--colorErrorLight); 
        }
        &.lx-btn-primary {
            &.warning:not([disabled]) {
                background-color:var(--colorWarning); 
                &:hover {
                    background-color: var(--colorWarningLight);
                }
            }
            &.success:not([disabled]) {
                background-color: var(--colorSuccess);
                &:hover {
                    background-color: var(--colorSuccessLight);
                }
            }
        }
    }
}