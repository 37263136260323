#lax {
    .lx-form-item {
        color: var(--colorText);
        margin: 0;
        &.required {
            .lx-form-item-control-input-content {
                > *:first-child  {
                    input::placeholder,
                    textarea::placeholder,
                    .lx-select-selection-placeholder  {
                        color: var(--colorErrorMedium);
                    }
                }
                > input::placeholder,
                > textarea::placeholder {
                    color: var(--colorErrorMedium);
                }
            }
            textarea {
                border-top-right-radius: 0;
            }
        }
        .lx-col {
            padding: 0;
            /* broken margin on radio label
            label {
                margin-top: 7px;
            }*/
            &.lx-form-item-control {
                margin-bottom: 7px;
            }
        } 
        .lx-form-item-explain {
            width: unset;
            min-width: 1em;
            min-height: 32px;
            padding: 6px 8px;
            text-align: start;
            text-decoration: none;
            word-wrap: break-word;
            background-color: var(--colorErrorOpacity);
            border-radius: 5px;
            box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
            box-sizing: border-box;
            font-size: var(--fontSize);
            font-weight: normal;
            position: absolute;
            right: 0;
            transform: translate(calc( 100% + 11px));
            z-index: 3;
            top: -33px;
            max-width: 250px;
            &:before {
                content: '';
                width: 0;
                height: 0;
                border-top: 6px solid transparent;
                border-bottom: 6px solid transparent;
                border-right: 6px solid var(--colorErrorOpacity);
                position: absolute;
                left: -6px;
                top: 10px;
            }
            > .lx-form-item-explain-error {
                color: #fff;
            }
        }
    }
}