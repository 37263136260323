#lax {
    .table-filter-dropdown-dates {
        .lx-picker {
            padding: 0 11px;
            margin-bottom: 5px;
            border-radius: 5px;
            background-color: white;
            > .lx-picker-input {
                padding: 0px 11px;
                width: 100px;
                >input {
                    margin-bottom: 0;
                }
            }
        }
    }
}
    