#lax {
    .lookup-popover {
        padding: 0;
        &.w700 {
            width: 700px;
        }
        &.w850 {
            width: 850px;
        }
        &.w1000 {
            width: 1000px;
        }
        .lx-popover-inner {
            padding: 0;
        }
        &.lx-popover-placement-bottom,
        &.lx-popover-placement-bottomLeft,
        &.lx-popover-placement-bottomRight {
            .lx-popover-arrow:after {
                background-color: var(--colorPrimary);
            }
        }
        &.warning {
            &.lx-popover-placement-bottom,
            &.lx-popover-placement-bottomLeft,
            &.lx-popover-placement-bottomRight {
                .lx-popover-arrow:after {
                    background-color: var(--colorWarning);
                }
            }
        }
    }
    .lookup-dropdown-table {
        .lx-spin-dot {
            top: 180px;
        }
        .lx-table {
            border-radius: var(--inputBorderRadius);
            .new {
                color: var(--colorPrimary);
                font-weight: bold;
                font-size: 9px;
            }
            .lx-table-title {
                padding: 5px 12px;
                text-align: center;
                font-weight: bold;
                color: white;
                font-size: var(--fontSizeXL);
            }
            thead {
                .lx-table-cell {
                    padding: 6px;
                    color: var(--textColor);
                    font-weight: normal;
                    .lx-input-status-error,
                    .lx-select-status-error > .lx-select-selector,
                    .lx-form-item-has-error .input-phone {
                        border-color: var(--colorBorder);
                        box-shadow: none;
                    }
                }
                .lx-form-item-control {
                    margin-bottom: 0;
                }
            }
            .lx-table-body {
                height: 300px;
                tr.disabled {
                    .lx-table-cell-row-hover {
                        cursor: not-allowed;
                    }
                    label {
                        pointer-events: none;
                        .lx-radio-inner {
                            background-color: var(--backgroundDisabled);
                        }
                    }
                    td {
                        color: var(--colorDisabled);
                    }
                }
                td {
                    &.lx-table-cell-row-hover {
                        cursor: pointer;
                    }
                    &:has(.lx-empty) {
                        border-bottom: 0px;
                    }
                }
                .lx-empty {
                    margin-top: 40px;
                }
            }
            .lx-table-footer {
                display: grid;
                grid-template-columns: 1fr auto auto;
                > span {
                    line-height: 32px;
                    margin-left: 14px;
                }
                button {
                    padding: 0 55px;
                }
            }
        }
    }
}