#lax {
    .auto-complete-dropdown {
        .lx-select-item {
            line-height: 125%;
            &.lx-select-item-option-selected {
                font-weight: normal;
            }
        }
        .lx-select-item-option-content {
            > div {
                font-weight: bold;
            }
            margin: 0;
        }
        .auto-complete {
            background: white;
            height: 40px;
            padding-top: 10px;
            padding-right: 18px;
            text-align: right;
            font-weight: bold;
            color: var(--colorPrimary);
            .lx-spin {
                position: absolute;
                right: 50%;
            }
        }
        .auto-complete-footer {
            padding: 12px 0 7px 14px;
            border-top: 1px var(--colorBorder) solid;
        }
    }
}