.lx-spin-text {
    margin-top: 20px;
}

.spin-empty {
    min-height: 52px;
    position: relative!important;
}
.empty-in-spin {
    margin-block: 5px;
    .lx-empty-image {
        height: 32px;
        margin: 0;
        svg {
            path { fill: #FAFAFA; }
        }
    }
    .lx-empty-description {
        font-size: var(--fontSizeXS);
    }
}