#lax {
    .pac-container {
        border-radius: 6px;
        padding: 4px;
        box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05);
        .pac-item {
            cursor: pointer;
            color: var(--colorText);
            border: none;
            padding: 2px 12px;
            font-size: 12px;
            border-radius: 4px;
            color:rgba(23, 43, 77, 0.88); 
            &:hover {
                background-color: #F7F7F8;
            }
            .pac-item-query {
                color: rgba(23, 43, 77, 0.77);
            }
        }
        &:after {
            margin: 10px 15px 0 0;
            background-size: 20%;
        }
    }
}