#lax{
    .lx-alert {
        padding: 12px 16px;
        .lx-alert-message {
            font-weight: bold;
        }
        &.lx-alert-warning {
            background-color: #FEF5EB;
            border: 1px var(--colorDirectiveOrange) solid;
            color: var(--colorWarningDark);
            .lx-alert-message, .lx-alert-description {
                color: var(--colorWarningDark);
            }
        }
        &.lx-alert-info {
            border: 1px var(--colorDirectiveInfoBorder) solid; 
            background-color: var(--colorInfoLight);
            color: var(--colorInfo);
            .anticon {
                color: var(--colorPrimary);
            }
            .lx-alert-message, .lx-alert-description {
                color: var(--colorInfo);
            }
        }
        &.lx-alert-error {
            background-color: var(--colorErrorLight);
            border: 1px var(--colorErrorMedium) solid;
            color: var(--colorErrorDark);
            .lx-alert-message, .lx-alert-description {
                color: var(--colorErrorDark);
            }
        }
    }
}