#lax {
    .btn-alt-value {
        &:disabled {
            &:not(.selected) {
                background-color: var(--backgroundDisabled);
                span {
                    opacity: .6;
                    color: var(--colorDisabled);
                }
            }
            &.selected {
                opacity: .4;
            }
        }
    }
    .form-item-TEXTAREA.required .lx-input-textarea-affix-wrapper {
        border-top-right-radius: 0px!important;
        right: -1px;
    }
    .form-item.lx-form-item-has-error  {
        .btn-alt-value {
            border-top-color: var(--colorErrorOpacity);
            border-bottom-color: var(--colorErrorOpacity);
            &.btn-unk {
                border-right-color: var(--colorErrorOpacity);
            }
        }
    }
    .form-item {
        .lx-picker, 
        .lx-select {
            width: 100%;
            .lx-select-selection-item {
                // Hack -> then the selected item label is longer then the field 
                width: 1px;
            }
            
        }
        &.form-item-BOOLEAN, 
        &.form-item-CHECKBOX {
            .form-item-group {
                .lx-switch {
                    border-radius: 100px;
                }
                .btn-unk, .btn-ref {
                    position: absolute;
                }
                .btn-ref {
                    right: 0;
                }
                .btn-unk {
                    right: 32px;
                    border-top-left-radius: var(--borderRadius);
                    border-bottom-left-radius: var(--borderRadius);
                }
            }
        }
        &.show-if{
            animation: append-animate .2s linear;
        }
        .form-item-group {
            display: flex;
            button.lx-btn-icon-only {
                min-width: 32px;
                border-width: 1px;
            }
        }
        
        .autofilled-xl {
            --background: #8bc679df;
            color: white;
            font-size: var(--fontSize);
            font-weight: normal;
            position: absolute;
            transform: translate(calc( 100% + 11px));
            z-index: 3;
            top: 0px;
            right: -15px;
            border-radius: 5px;
            box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
            padding: 6px 8px;
            background-color: var(--background);
            &:before {
                content: '';
                width: 0;
                height: 0;
                border-top: 6px solid transparent;
                border-bottom: 6px solid transparent;
                border-right: 6px solid var(--background);
                position: absolute;
                left: -6px;
                top: 10px;
            }
        }
    }
    .lx-autofilled {
        
        .lx-tooltip-arrow {
            --antd-arrow-background-color: #5aca74d8;
        }
        .lx-tooltip-inner {
            background-color: #5aca74d8;
        }
    }
}


@keyframes append-animate {
	from {
		transform: scaleY(0);
		opacity: 0;
	}
	to {
		transform: scaleY(1);
		opacity: 1;	
	}
}