:root .PhoneInput {
    --icon-height: 14px;
    --icon-aspectRatio: 1.4;
}

#lax {
    .PhoneInput {
        padding: 0 0 0 10px;
        border: 1px var(--colorBorder) solid;
        border-radius: 5px;
        transition: all 0.2s;
        display: flex;
	    align-items: center;
        &:has(input:focus) {
            border-color: var(--colorPrimary);
            box-shadow: 0 0 0 2px rgba(5, 138, 255, 0.06);
            outline: 0;
        }
        &.PhoneInput--disabled {
            background-color: var(--backgroundDisabled);
            color: var(--colorText);
        }
        .PhoneInputCountry {
            position: relative;
            display: flex;
            align-items: center;
            margin-right: 6px;
            .PhoneInputCountrySelectArrow {
                display: none;
            }

            .PhoneInputCountryIcon {
                width: calc(var(--icon-height) * var(--icon-aspectRatio));
                height: var(--icon-height);
                background-color: transparent;
                > img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    border: 0;
                }
            }
            select {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                z-index: 1;
                border: 0;
                opacity: 0;
                cursor: pointer;
                &:disabled,
                &:readonly {
                    cursor: default;
                }
            }
        }
        input {
            flex: 1;
		    min-width: 0;
            height: 30px;
            color: var(--colorTest);
            font-size: var(--fontSize);
            line-height: 1.66;
            margin-left: 6px;
            background-color: transparent;
        }
        
    }
}

#lax {
    .form-item-group .input-phone {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
    }
    
    .lx-form-item-has-error .PhoneInput,
    .lx-form-item-has-error .input-phone  {
        border-color: var(--colorError);
        &:focus {
            border-color: var(--colorError);
            box-shadow: 0 0 0 2px rgba(255, 45, 5, 0.1);
            outline: 0;
        }
    }
    .input-phone {
        border-width: 1px;
        border-style: solid;
        border-color: var(--colorBorder);
        font-size: var(--fontSize);
        line-height: 1.67;
        border-radius: 5px;
        width: 100%;
        padding: 4px 11px;
        color: var(--colorTest);
        &:focus {
            border-color: var(--colorPrimary);
            box-shadow: 0 0 0 2px rgba(5, 138, 255, 0.06);
            outline: 0;
            background-color: #ffffff;
        }
    }
}
