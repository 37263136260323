#lax {
    .form-item-copy {
        width: 100%;
        text-align: right;
        margin-top: 10px;
        font-size: var(--fontSizeS);
        > button {
            margin-left: 5px;
        }
    }
}