
#lax {
    .lx-table-filter-dropdown {
        max-height: 60vh;
        padding: 15px;
        .lx-table-filter-dropdown-btns {
            padding: 10px 0 0 0;
            margin-top: 10px;
        }
        .lx-checkbox-group {
            max-height: 330px;
            overflow-x: hidden;
            display: grid;
            grid-template-columns: auto;
            row-gap: 10px;
        }
        
        .lx-input-search {
            display: block;
            margin-bottom: 13px;
        }
        .lx-input {
            background: white;
            border-color: var(--colorBorder);
        }
        .lx-input-search-button {
            background-color: white;
        }
        .empty  {
            display: block;
            font-size: var(--fontSizeL);
            color: rgba(0, 0, 0, 0.25);
        }
    }
}
