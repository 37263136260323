#lax {
    .lax-result {
        width: 100%;
        height: 450px;
        padding-top: 60px;
        > div {
            display: grid;
            grid-template-rows: 300px 30px auto;
            grid-row-gap: 20px;
            justify-content: center;
            h1 {
                font-weight: bold;
                font-size: 18px;
                text-align: center;
            }
            img {
                width: 300px;
                align-self: self-end;
            }
            button {
                position: relative;
                overflow: hidden;
            }
        }
    }
}