#lax {
    .lx-form-item-control-input {
        min-height: unset;
    }
    input {
        font-weight: bold;
    }
    .lx-input-suffix {
        > .anticon {
            color: var(--colorInputIcon);
        }
    }
}