.floating-panel {
    width: 300px;
    height: fit-content;
    position: sticky;
    justify-self: end;
    font-size: var(--fontSizeS);
    display: block;
    box-shadow: 0 1px 2px 0 rgba(9,30,66,.25);
    .floating-panel-header {
        min-height: 33px;
        border-radius: 6px 0px;
        color: white;
        font-weight: bold;
        background-color: #C1C1C1;
        display: grid;
        align-items: center;
        padding: 0 9px;
        font-size: var(--fontSize);
    }
    .floating-panel-body {
        height: 100%;
        padding-top: 15px;
        padding-left: 10px;
        background: linear-gradient(125deg, #ffffff 0%, #F9F9F9 40%, #F6F6F6 100%);
        border-left: 1px var(--colorBorder) solid;
    }
}