#lax {
    .btn-est  {
        &.selected, &:not([disabled]):hover {
            background-color: var(--colorPrimary);
            .anticon{
                color: white;
            }
        }
        .anticon{
            color: var(--colorPrimary);
        }
    }
}