#lax {
    --backgroundDisabled: #F0F0F1;
    form > .directive.warning, 
    form > .directive.info {
        box-shadow: var(--fieldsetBoxShadow);
        border-color: transparent!important;
    }
    .directive {
        margin: 15px 0;
        padding: 10px;
        border-radius: var(--inputBorderRadius);
        border: 1px var(--colorDirectiveInfoBorder) solid;
        background-color: var(--colorInfoLight);
        color: var(--colorInfo);
        &:has(.has-value),
        &.disabled,
        &.lx-form-item-has-success {
            border-color: var(--backgroundDisabled)!important;
            background-color: var(--backgroundDisabled)!important;
            * {
                color: var(--colorText);
            }
        }
        
        &.lx-form-item-has-error {
            button {
                border-color: var(--colorError)!important;
            } 
        }
        &:not(:has(.has-value)) {
            &.warning{
                background-color: #FEF5EB;
                border: 1px var(--colorDirectiveOrange) solid;
                color: var(--colorWarningDark);
                label {
                    color: var(--colorWarningDark);
                }
                button {
                    background-color: var(--colorDirectiveOrange);
                    border-color: var(--colorDirectiveOrange);
                }
            }
            button {
                background-color: var(--colorDirectiveOrange);
                border-color: var(--colorDirectiveOrange);
            }
        }
        &.info {
            button {
                background-color: var(--colorDirectiveInfo);
                border-color: var(--colorDirectiveInfo);
            }
        }
        button:disabled {
            color: white;
            opacity: .2;
        }
        > .lx-row {
            flex-direction: column;
            >.lx-form-item-label {
                text-align: start;
            }
            >.lx-form-item-control {
                margin-bottom: 0;
            }
        }
        label {
            color: var(--colorInfo);
            min-height: 32px;
            height: auto;
            display: block; 
            >.anticon {
                font-size: 48px;
                float: left;
                margin-right: 10px;
                margin-bottom: 10px;
                .warning {
                    color: var(--colorWarningDark);
                }
                .info {
                    color: var(--colorInfo);    
                }            
            }
        }
    }
    .directive.directive-CONFIRM {
        button {
            &:not(:disabled) {
                background-color: var(--colorDirectiveInfo);
                border-color: var(--colorDirectiveInfoBorder);
                color: white;
                &:hover {
                    opacity: .8;
                }
            }
        }
        &.warning {
            button:not(:disabled) {
                background-color: var(--colorDirectiveOrange)!important;
                border-color: var(--colorDirectiveOrange);
            }
        }
    }
}