#lax {
        
    .anticon{
        &.info {
            color: var(--colorPrimary);
        }
        &.success {
            color: var(--colorSuccess);
        }
        &.error {
            color: var(--colorError);
        }
    }
}