#lax {
    .lx-modal, .lx-table-wrapper {
        &.warning, &.info, &.danger, &.success {
            .lx-modal-header {
                text-align: center;
                > .lx-modal-title {
                    color: white;
                }
            }
        }
        &.warning {
            .lx-modal-header, .lx-table-title {
                background: var(--colorWarning);
            }
            h2 {
                color: var(--colorWarning);
            }
            button.lx-btn-primary:not(.lx-btn-icon-only):not([disabled]) {
                background-color:var(--colorWarning);
            }
        }
        &.danger {
            .lx-modal-header, .lx-table-title {
                background: var(--colorError);
            }
            
            h2 {
                color: var(--colorError);
            }
            button.lx-btn-primary:not(.lx-btn-icon-only):not([disabled]) {
                background-color:var(--colorError);
            }
        }
        &.success {
            .lx-modal-header, .lx-table-title {
                background: var(--colorSuccess);
            }
            h2 {
                color: var(--colorSuccess);
            }
            button.lx-btn-primary:not(.lx-btn-icon-only):not([disabled]) {
                background-color:var(--colorSuccess);
            }
        }
        &.info {
            .lx-modal-header, .lx-table-title {
                background: var(--colorPrimary);
            }
            h2 {
                color: var(--colorPrimary);
            }
            button.lx-btn-primary:not(.lx-btn-icon-only):not([disabled]) {
                background-color:var(--colorPrimary);
            }
        }
    }
}