#lax {
    .lx-cascader-menus {
        .lx-cascader-menu {
            width: 200px;
            height: 76px;
            border-inline-end: none;
            &:nth-of-type(2) {
                width: 300px;
                height: 420px;
                border-inline-start: 1px solid rgba(5, 5, 5, 0.06);
            }
        }
    }
}