
.lx-table {
    --tableHighlightColor:  var(--colorPrimary);
    --tableTextColor:  var(--colorText);
    --tableBackground: var(--appBackground);
}

#lax {
    .lx-table-wrapper:not(.white) {
        .lx-table {
            background-color: var(--tableBackground); 
            .lx-table-cell-fix-left, 
            .lx-table-cell-fix-right {
                background-color: var(--tableBackground);
            }
        }
    }
    .lx-table {
        .lx-table-body {
            border-bottom: 1px var(--colorBorder) solid;
        }
        tr {
            > td {
                
                > a {
                    text-decoration: underline;
                    font-weight: bold;
                    color: var(--colorText);
                }
                .lx-btn-icon-only {
                    background-color: transparent;
                }
            }
        }
    }
}