#lax {
    --colorIncident: #BBBEC8;

    --colorIncidentWC: #FF9039;
    --colorIncidentAU: #8285ED;
    --colorIncidentLB: #a765e7;
    --colorIncidentWV: #AF675D;
    --colorIncidentHPL: #975790;
    --colorIncidentAA: #CFDB59;
    --colorIncidentPR: #D8CA65;
    --colorIncidentMC: #A2C58B;
}
.incident-type-icon {
    width: 20px;
    height: 20px;
    background-size: 65%;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: var(--colorIncident);
    background-image: url(../../images/icons/incident-Default.svg);

    &.incident-type-icon-WC {
        background-color: var(--colorIncidentWC);
        background-image: url(../../images/icons/incident-WC.svg);
    }
    &.incident-type-icon-AU {
        background-color: var(--colorIncidentAU);
        background-image: url(../../images/icons/incident-AU.svg);
    }
    &.incident-type-icon-LB {
        background-color: var(--colorIncidentLB);
        background-image: url(../../images/icons/incident-LB.svg);
    }
    &.incident-type-icon-WV {
        background-color: var(--colorIncidentWV);
        background-image: url(../../images/icons/incident-WV.svg);
        background-size: 55%;
    }
    &.incident-type-icon-HPL {
        background-color: var(--colorIncidentHPL);
        background-image: url(../../images/icons/incident-HPL.svg);
        background-size: 70%;
    }
    &.incident-type-icon-AA {
        background-color: var(--colorIncidentAA);
        background-image: url(../../images/icons/incident-AA.svg);
    }
    &.incident-type-icon-PR {
        background-color: var(--colorIncidentPR);
        background-image: url(../../images/icons/incident-PR.svg);
    }
    &.incident-type-icon-MC {
        background-color: var(--colorIncidentMC);
        background-image: url(../../images/icons/incident-MC.svg);
        background-size: 60%;
    }
    /*&.incident-type-icon-NR {
        background-color: var(--colorIncidentNR);
        background-image: url(../../images/icons/incident-NR.svg);
    } */
}
