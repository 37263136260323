#lax {
    .input-time {
        .lx-input-suffix {
            cursor: pointer;
            .anticon-clock-circle {
                color: var(--colorInputIcon);
            }
            
            &:hover {
                .anticon-close-circle {
                    transition: opacity 0.3s ease;
                    opacity: 1;
                }
            }
            .anticon-close-circle {
                font-size: var(--fontSize);
                right: -20px;
                opacity: 0;
                color: var(--colorGray);
            }
            .lx-input-clear-icon {
                z-index: 10;
            }
        }
    }
}