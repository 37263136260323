.debaunce-select-dropdown {
    .lx-select-item-option-content {
        > div {
            font-weight: bold;
        }
        margin: 7px 0;
    }
    .debounce-select-footer {
        background: white;
        height: 40px;
        padding-top: 10px;
        padding-right: 18px;
        text-align: right;
        font-weight: bold;
        color: var(--colorPrimary);
        .lx-spin {
            position: absolute;
            right: 50%;
        }
    }
    
}