#lax {
    .lx-modal {
        .lx-modal-content {
            padding: 0;
            .lx-modal-header {
                padding: 0 15px;
                .lx-modal-title{
                    padding: 10px 0;
                }
            }
            .lx-modal-body {
                padding: 0 12px 12px 12px;
            }
            .lx-modal-footer {
                border-top: 1px solid var(--colorLine);
                margin: 0 15px;
                padding: 15px 0;
                .lx-btn {
                    width: 165px;
                }
            }
        }
    }
}